import axios from '@axios'

export default function associatedsServives() {
  const getByYear = async (queryParams) => {
    const api = await axios.get('associateds/year', queryParams)
    return api.data.response
  }

  return {
    getByYear,
  }
}
